export default [
  {
    path: '/report-uploader',
    name: 'report-uploader.list.index',
    component: () => import('@/views/report-uploader/list/Index.vue'),
    meta: {
      pageTitle: 'Report Uploader',
      breadcrumb: [
        {
          text: 'Report Uploader',
          active: false,
        },
      ],
      action: 'read',
      resource: 'DailyReportUploader',
    },
  },
  {
    path: '/report-uploader/:report/upload',
    name: 'report-uploader.upload.index',
    props: true,
    component: () => import('@/views/report-uploader/upload/Index.vue'),
    meta: {
      pageTitle: 'Report Uploader',
      breadcrumb: [
        {
          text: 'Report Uploader',
          to: { name: 'report-uploader.list.index' },
        },
        {
          text: 'Upload',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DailyReportUploader',
    },
  },
]
