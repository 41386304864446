export default [
  {
    path: '/copy-editor/products',
    name: 'copy-editor.products.index',
    component: () => import('@/views/copy-editor/products/Index.vue'),
    meta: {
      pageTitle: 'Products Search',
      breadcrumb: [
        {
          text: 'Copy Editor',
        },
        {
          text: 'Products Search',
          active: true,
        },
      ],
      action: 'read',
      resource: 'CopyEditor',
    },
  },
  {
    path: '/copy-editor/tasks',
    name: 'copy-editor.tasks.index',
    component: () => import('@/views/copy-editor/tasks/Index.vue'),
    meta: {
      pageTitle: 'Tasks',
      breadcrumb: [
        {
          text: 'Copy Editor',
        },
        {
          text: 'Tasks',
          active: true,
        },
      ],
      action: 'read',
      resource: 'CopyEditor',
    },
  },
  {
    path: '/copy-editor/tasks/:id/products/edit',
    name: 'copy-editor.products.edit',
    props: true,
    component: () => import('@/views/copy-editor/product-editor/Index.vue'),
    meta: {
      pageTitle: 'Product Edit',
      breadcrumb: [
        {
          text: 'Copy Editor',
        },
        {
          text: 'Tasks',
          to: { name: 'copy-editor.tasks.index' },
        },
        {
          text: 'Product Edit',
          active: true,
        },
      ],
      action: 'read',
      resource: 'CopyEditor',
    },
  },
  {
    path: '/copy-editor/notifications/',
    name: 'copy-editor.notifications.index',
    component: () => import('@/views/copy-editor/notifications/Index.vue'),
    meta: {
      pageTitle: 'Brand Contacts',
      breadcrumb: [
        {
          text: 'Copy Editor',
        },
        {
          text: 'Brand Contacts',
          active: true,
        },
      ],
      action: 'read',
      resource: 'CopyEditor',
    },
  },
  {
    path: '/copy-editor/tasks/:id/products/preview',
    name: 'copy-editor.products.preview',
    component: () => import('@/views/copy-editor/preview/AuthPreview.vue'),
    props: true,
    meta: {
      action: 'read',
      resource: 'CopyEditor',
      layout: 'amazon',
    },
  },
  {
    path: '/editor/products/preview/:id',
    name: 'copy-editor.products.preview.brand',
    component: () => import('@/views/copy-editor/preview/BrandPreview.vue'),
    props: true,
    meta: {
      action: 'read',
      resource: 'Auth',
      layout: 'amazon',
    },
  },
]
