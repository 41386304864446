import gql from 'graphql-tag'
import apollo from '@/libs/apollo'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchAll(ctx, params = {}) {
      return apollo.query({
        query: gql`
          query contacts($brand: Int, $limit: Int = 25, $page: Int = 1, $search: String) {
            brandManagerContacts(page: $page, search: $search, brand: $brand, limit: $limit, isActive: false) {
                data {
                  id
                  name
                  title
                  email
                  brand {
                      id,
                      brand_name
                  }  
                  notificationSetting {
                      id,
                      time
                      is_active
                      period
                      start_date
                      timezone
                  }  
                },
                total
                to
                from
                current
                perPage
            }
        }
        `,
        variables: params,
      })
    },
    async create(ctx, params = {}) {
      return apollo.mutate({
        mutation: gql`
          mutation addBrandContact($data: CreateBrandContactInput!) {
              addBrandContact(data: $data) {
                 id
                 name
                 title
                 email
             }
          }
        `,
        variables: {
          data: params,
        },
      })
    },
    async update(ctx, { id, data }) {
      return apollo.mutate({
        mutation: gql`
            mutation updateBrandContact($id: ID!, $data: UpdateBrandContactInput!) {
                updateBrandContact(id: $id, data: $data) {
                    id
                    name
                    title
                    email
                }
            }
        `,
        variables: {
          data,
          id,
        },
      })
    },
    async toggle(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
            mutation toggleContact($id: ID!) {
                toggleContact(id: $id) {
                    id
                }
            }
        `,
        variables: {
          id,
        },
      })
    },
    async toggleNotification(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
          mutation toggleNotificationSetting($id: ID!) {
              toggleNotification(id: $id) {
                  id
              }
          }
        `,
        variables: {
          id,
        },
      })
    },
    async toggleNotificationIssuer(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
          mutation toggleNotificationSetting($id: ID!) {
              toggleNotificationIssuer(id: $id) {
                  id,
              }
          }
        `,
        variables: {
          id,
        },
      })
    },
    async toggleBulkReceiveNotification(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
          mutation toggleBulkReceiveNotificationSettings($id: ID!) {
              toggleBulkReceiveNotification(id: $id) {
                  id,
              }
          }
        `,
        variables: {
          id,
        },
      })
    },
    async toggleFbmListingNotification(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
          mutation toggleFbmListingNotificationSettings($id: ID!) {
              toggleFbmListingNotification(id: $id) {
                  id,
              }
          }
        `,
        variables: {
          id,
        },
      })
    },
    async toggleForecastNotification(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
          mutation toggleForecastingNotificationSettings($id: ID!) {
            toggleForecastingNotification(id: $id) {
                  id,
              }
          }
        `,
        variables: {
          id,
        },
      })
    },
    async toggleAmazonShipmentsReceivingNotification(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
                  mutation toggleAmazonShipmentsReceivingNotification($id: ID!) {
                      toggleAmazonShipmentsReceivingNotification(id: $id) {
                          id,
                      }
                  }
              `,
        variables: {
          id,
        },
      })
    },
    async remove(ctx, { id }) {
      return apollo.mutate({
        mutation: gql`
                  mutation removeContactNotification($id: ID!) {
                      removeBrandContact(id: $id)
                  }
              `,
        variables: {
          id,
        },
      })
    },
  },
}
