import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import auth from './auth'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import operationSnapshot from './reports/operationSnapshot'
import inventorySnapshot from './reports/inventorySnapshot'
import comments from './comments'
import reportUploader from './reports/uploader'
import brands from './brands'
import recordDelivery from './record-delivery'
import printers from './printers'
import products from './products'
import tasks from './tasks'
import copyEditor from './copy-editor'
import contacts from './contacts'
import user from './user'
import business from './reports/business'
import packaging from './packaging'
import channels from './channels'
import countries from './countries'
import outbound from './outbound'

Vue.use(Vuex)

const persist = createPersistedState({
  paths: [
    'auth.user',
    'inventorySnapshot.fields',
    'operationSnapshot.fields',
    'outbound.erased_cases',
  ],
})

export default new Vuex.Store({
  modules: {
    app,
    auth,
    appConfig,
    verticalMenu,
    operationSnapshot,
    inventorySnapshot,
    comments,
    reportUploader,
    brands,
    recordDelivery,
    printers,
    products,
    tasks,
    copyEditor,
    contacts,
    user,
    business,
    packaging,
    channels,
    countries,
    outbound,
  },
  strict: process.env.DEV,
  plugins: [
    persist,
  ],
})
