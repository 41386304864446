export default {
  namespaced: true,
  state: {
    erased_cases: [],
  },
  getters: {
    getErasedCases({ erased_cases }) {
      // eslint-disable-next-line camelcase
      return erased_cases
    },
  },
  mutations: {
    SET_ERASED_CASES(state, erasedCase = {}) {
      state.erased_cases.push({ ...erasedCase, index: state.erased_cases.length })
    },
    RESET_ERASED_CASES(state) {
      state.erased_cases = []
    },
    REMOVE_SPECIFIC_ERASED_CASE(state, index) {
      state.erased_cases.splice(index, 1)
    },
  },
}
