import { areaEnum } from '@/utils/area.enum'

export default [
  {
    path: '/purchase-order/create',
    name: 'purchase-order.create',
    component: () => import('@/views/record-delivery/order/create/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Create',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        {
          text: 'Purchase Order Create',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DeliveryOrder',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/list',
    name: 'purchase-order.list',
    component: () => import('@/views/record-delivery/list/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order List',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          active: true,

        },
      ],
      action: 'read',
      resource: 'DeliveryOrder',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/details/',
    name: 'purchase-order.details',
    props: true,
    component: () => import('@/views/record-delivery/order/details/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Details',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        {
          text: 'Purchase Order Details',
          active: true,
        },
      ],
      action: 'read',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/shipment/:shipmentId/pallet/create/',
    name: 'purchase-order.pallet.create',
    props: true,
    component: () => import('@/views/record-delivery/order/pallet/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Pallet Create',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        router => ({
          text: 'Purchase Order Details',
          to: {
            name: 'purchase-order.details',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Purchase Order Pallet Create',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/pallet/create/',
    name: 'purchase-order.pallet.create.withoutId',
    props: true,
    component: () => import('@/views/record-delivery/order/pallet/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Pallet Create',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        {
          text: 'Purchase Order Pallet Create',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/shipment/:shipmentId/pallet/:palletId/scan',
    name: 'purchase-order.pallet.scan',
    props: true,
    component: () => import('@/views/record-delivery/order/scan/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Scan',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        router => ({
          text: 'Purchase Order Details',
          to: {
            name: 'purchase-order.details',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Purchase Order Scan',
          active: true,
        },
      ],
      action: 'read',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/summary/',
    name: 'purchase-order.summary',
    props: true,
    component: () => import('@/views/record-delivery/order/summary/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Summary Report',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        router => ({
          text: 'Purchase Order Details',
          to: {
            name: 'purchase-order.details',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Purchase Order Summary Report',
          active: true,
        },
      ],
      action: 'read',
      resource: 'DeliveryOrder',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/summary/view/:orderStatus/',
    name: 'purchase-order.summary.view',
    props: true,
    component: () => import('@/views/record-delivery/order/summary/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Summary',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        router => ({
          text: 'Purchase Order Details',
          to: {
            name: 'purchase-order.details',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Purchase Order Summary',
          active: true,
        },
      ],
      action: 'update',
      resource: 'DeliveryOrder',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/print',
    name: 'purchase-order.label-print',
    component: () => import('@/views/record-delivery/print-labels/Index.vue'),
    meta: {
      pageTitle: 'Label Reprinting',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Label Reprinting',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/case-return',
    name: 'purchase-order.case-return',
    component: () => import('@/views/record-delivery/manual-case/Index.vue'),
    meta: {
      pageTitle: 'Case Add',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Case Add',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/pallet/create/',
    name: 'purchase-order.pallet.create.multi-shipment',
    props: true,
    component: () => import('@/views/record-delivery/order/pallet/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Pallet Create',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        router => ({
          text: 'Purchase Order Details',
          to: {
            name: 'purchase-order.details',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Purchase Order Pallet Create',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/shipment/create/',
    name: 'purchase-order.shipment.create',
    props: true,
    component: () => import('@/views/record-delivery/order/shipment/create/Index.vue'),
    meta: {
      pageTitle: 'Purchase Order Shipment Create',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        router => ({
          text: 'Purchase Order Details',
          to: {
            name: 'purchase-order.details',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Purchase Order Shipment Create',
          active: true,
        },
      ],
      action: 'create',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/purchase-order/:id/bulk/:bulkId',
    name: 'bulk-receive.progress',
    props: true,
    component: () => import('@/views/record-delivery/order/bulk-receive-progress/Index.vue'),
    meta: {
      pageTitle: 'Bulk receive',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Purchase Order List',
          to: { name: 'purchase-order.list' },
        },
        router => ({
          text: 'Purchase Order Details',
          to: {
            name: 'purchase-order.details',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Purchase Order Scan',
          active: true,
        },
      ],
      action: 'read',
      resource: 'DeliveryPallet',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
  {
    path: '/delivery/printers',
    name: 'delivery.printers',
    component: () => import('@/views/record-delivery/printers/Index.vue'),
    meta: {
      pageTitle: 'Printers List',
      breadcrumb: [
        {
          text: 'Deliveries',
        },
        {
          text: 'Printers List',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Printer',
      area: areaEnum.RECEIVE_DELIVERY,
    },
  },
]
