export default [
  {
    path: '/forecast/seasonality',
    name: 'forecast.seasonality.index',
    component: () => import('@/views/forecast/seasonality/Index.vue'),
    meta: {
      pageTitle: 'Seasonality',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Seasonality',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Forecast',
    },
  },
  {
    path: '/forecast/brr',
    name: 'forecast.brr.index',
    component: () => import('@/views/forecast/brr/Index.vue'),
    meta: {
      pageTitle: 'Base Run Rate',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Base Run Rate',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Forecast',
    },
  },
  {
    path: '/forecast/growth',
    name: 'forecast.growth.index',
    component: () => import('@/views/forecast/growth/Index.vue'),
    meta: {
      pageTitle: 'Growth',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Growth',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Forecast',
    },
  },
  {
    path: '/forecast/buy-box',
    name: 'forecast.buy-box.index',
    component: () => import('@/views/forecast/buy-box/Index.vue'),
    meta: {
      pageTitle: 'Buy Box',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Buy Box',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Forecast',
    },
  },
  {
    path: '/forecast/demand-details',
    name: 'forecast.demand-details.index',
    component: () => import('@/views/forecast/demand-details/Index.vue'),
    meta: {
      pageTitle: 'Demand Details',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Demand Details',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Forecast',
    },
  },
  {
    path: '/forecast/forecast-vs-actuals',
    name: 'forecast.forecast_actuals.index',
    component: () => import('@/views/forecast/forecast-actuals/Index.vue'),
    meta: {
      pageTitle: 'Forecast vs Actuals',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Forecast vs Actuals',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Forecast',
    },
  },
  {
    path: '/forecast-configuration',
    name: 'forecast.configurator',
    component: () => import('@/views/forecast/doi-configuration/Index.vue'),
    meta: {
      pageTitle: 'Forecast DOI Configuration',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Forecast DOI Configuration',
          active: false,
        },
      ],
      action: 'read',
      resource: 'Forecast',
    },
  },
  {
    path: '/snapshot',
    name: 'forecast.snapshot',
    component: () => import('@/views/forecast/forecast-approval/Index.vue'),
    meta: {
      pageTitle: 'Forecast Step Chart',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Forecast Step Chart',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Forecast',
    },
  },
  {
    path: '/buy-plan',
    name: 'forecast.buy_plan',
    component: () => import('@/views/forecast/buy-plan/Index.vue'),
    meta: {
      pageTitle: 'Buy Plan',
      breadcrumb: [
        {
          text: 'Forecasting',
        },
        {
          text: 'Buy Plan',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Forecast',
    },
  },
]
