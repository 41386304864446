const defaultPackage = {
  id: undefined,
  fnsku: undefined,
  product_name: undefined,
  production_date: undefined,
  expiration_date: undefined,
  case_pack_quantity: undefined,
  so_case_pack_quantity: undefined,
}

export default {
  namespaced: true,
  state: {
    current_package: { ...defaultPackage },
  },
  mutations: {
    UPDATE_CURRENT_PACKAGE(state, data = {}) {
      state.packaging.current_package = { ...data }
    },
    RESET_CURRENT_PACKAGE(state) {
      state.packaging.current_package = { ...defaultPackage }
    },
  },
}
