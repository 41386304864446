import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const useToast = () => Vue.$toast

export const ErrorToast = (message, title = 'Error') => ({
  component: ToastificationContent,
  props: {
    title,
    variant: 'danger',
    icon: 'AlertCircleIcon',
    text: message,
    delay: 3000,
  },
})

export const SuccessToast = (message, title = '') => ({
  component: ToastificationContent,
  props: {
    title,
    variant: 'success',
    icon: 'CheckCircleIcon',
    text: message,
  },
})

export const InfoToast = (message, title = '') => ({
  component: ToastificationContent,
  props: {
    title,
    variant: 'warning',
    icon: 'InfoIcon',
    text: message,
  },
})

export const SocketInfoToast = (message, title = '') => ({
  component: ToastificationContent,
  props: {
    title,
    variant: 'info',
    icon: 'InfoIcon',
    text: message,
  },
})

export const GenericToast = (message, options = {}) => ({
  component: ToastificationContent,
  props: {
    title: options.title,
    variant: options.variant,
    icon: options.icon,
    text: message,
    delay: options.delay ?? 3000,
  },
})

export default {
  ErrorToast,
}
