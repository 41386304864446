import gql from 'graphql-tag'
import apollo from '@/libs/apollo'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchAll(ctx, params = {}) {
      return apollo.query({
        query: gql`
          query tasks(
            $search: String
            $page: Int = 1
            $limit: Int = 25
            $brand: Int
            $user_id: Int
            $show_done: Boolean
          ) {
            tasks(
              search: $search
              page: $page
              limit: $limit
              brand: $brand
              user_id: $user_id
              show_done: $show_done
            ) {
              data {
                id
                title
                taskable_id
                taskable_type
                user_id
                created_by
                status
                created_by
                created_at
                productChanges{
                    field_key
                    field_value
                }
                responsible {
                  name
                    deleted_at
                }
                creator {
                  name
                    deleted_at
                }
                product {
                  sku
                  brand {
                    brand_name
                  }
                  label
                }
              }
              total
              to
              from
              current
              perPage
            }
          }
        `,
        variables: params,
      })
    },
    async fetchSingle(ctx, id) {
      return apollo.query({
        query: gql`
          query GetProductTask($id: ID!) {
            getProductTask(id: $id) {
              product {
                label
                sku
                brand_id
                brand {
                  id
                  brand_name
                }
                msrp
                amazon_title
                key_feature
                key_feature_2
                key_feature_3
                key_feature_4
                key_feature_5
                image_url
              }
              productAttribute {
                id
                description
                amazon_title
                key_feature
                key_feature_2
                key_feature_3
                key_feature_4
                key_feature_5
              }
              id
              status
              taskable_id
              created_by
              user_id
            }
          }
        `,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
    },
    async updateStatus(ctx, { status, id }) {
      return apollo.mutate({
        mutation: gql`
          mutation updateStatus($id: ID!, $status: TaskStatus!) {
            updateStatus(id: $id, data: { status: $status }) {
              id
            }
          }
        `,
        variables: {
          id,
          status,
        },
      })
    },
    async remove(ctx, id) {
      return apollo.mutate({
        mutation: gql`
          mutation removeTask($id: ID!) {
            removeProductTask(id: $id)
          }
        `,
        variables: {
          id,
        },
      })
    },
    async changes(ctx, attributeId) {
      return apollo.query({
        query: gql`
          query getProductChanges($id: ID!) {
            getTaskProductChanges(id: $id) {
              id
              field_key
              field_previous
              field_value
              comment
              created_at
            }
          }
        `,
        variables: {
          id: attributeId,
        },
      })
    },
  },
}
