export default [
  {
    path: '/brands',
    name: 'brands.index',
    component: () => import('@/views/brands/Index.vue'),
    meta: {
      pageTitle: 'Brands',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Brands',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Brand',
    },
  },
  {
    path: '/brands/:id/profile',
    name: 'brands.profile',
    component: () => import('@/views/brands/brand-profile/Index.vue'),
    meta: {
      pageTitle: 'Brand Profile',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        { text: 'Brands', to: { name: 'brands.index' } },

        {
          text: 'Brand Profile',
          active: true,
        },
      ],
      action: 'Update',
      resource: 'Brand',
    },
    props: true,
  },
]
