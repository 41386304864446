import axios from '@/libs/axios'
// import { set } from 'vue'
/* eslint-disable camelcase */

const currentBox = {
  barcode: '',
  fnsku: null,
  product_id: null,
  shelf_life: null,
  product_name: '',
  production_date: null,
  expiration_date: null,
  is_production_date_check: false,
  is_box_damaged: false,
  case_pack_quantity: undefined,
  is_incorrect_case_pack_quantity: false,
  is_barcode_recognized: false,
  damaged_quantity: undefined,
  partial_case_pack_quantity: undefined,
  is_partial_case: false,
  pictures: [],
}

const getDefaultState = () => ({
  scanned_box_quantity: null,
  damaged_boxes_pictures: [],
  current_box: { ...currentBox },
  unique_scanned_boxes: {},
  time_start: null,
  time_end: null,
  saved_current_box_default_barcode: null,
})

const getOrderDefaultState = () => ({
  po_number: null,
})

export default {
  namespaced: true,
  state: {
    pallet: { ...getDefaultState() },
    order: { ...getOrderDefaultState() },
  },
  getters: {
    getPallet({ pallet }) {
      return pallet
    },
    getBoxesQuantity({ pallet }) {
      return pallet.scanned_box_quantity
    },
    getUniqueBoxes({ pallet }) {
      return pallet.unique_scanned_boxes
    },
  },
  mutations: {
    UPDATE_PALLET_INFO(state, pallet = {}) {
      state.pallet = { ...state.pallet, ...pallet }
    },
    UPDATE_CURRENT_PRODUCT(state, product = {}) {
      state.pallet.current_box = { ...product }
    },
    RESET_CURRENT_BOX(state) {
      state.pallet.current_box = { ...currentBox }
    },
    setOrder(state, value) {
      state.order = value
    },
  },
  actions: {
    async getOrder(ctx, id) {
      return axios.get(`/shipment/purchase-orders/${id}`)
        .then(response => {
          ctx.commit('setOrder', response.data)
        })
    },
  },
}
