export default [
  {
    path: '/enforcement/smtp',
    name: 'enforcement.smtp.index',
    component: () => import('@/views/enforcement/smtp/Index.vue'),
    meta: {
      pageTitle: 'SMTP Configuration',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'SMTP Configuration',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/smtp/:id/edit',
    name: 'enforcement.smtp.edit',
    component: () => import('@/views/enforcement/smtp/Edit.vue'),
    meta: {
      pageTitle: 'SMTP Configuration/Edit',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'SMTP Configuration',
          to: { name: 'enforcement.smtp.index' },
        },
        {
          text: 'SMTP Edit',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
    props: true,
  },
  {
    path: '/enforcement/bcc',
    name: 'enforcement.bcc.index',
    component: () => import('@/views/enforcement/bcc/Index.vue'),
    meta: {
      pageTitle: 'BCC',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'BCC',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/bcc/:id/edit',
    name: 'enforcement.bcc.edit',
    component: () => import('@/views/enforcement/bcc/Edit.vue'),
    meta: {
      pageTitle: 'BCC/Edit',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'BCC',
          to: { name: 'enforcement.bcc.index' },
        },
        {
          text: 'BCC Edit',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
    props: true,
  },
  {
    path: '/enforcement/sellers',
    name: 'enforcement.sellers.index',
    props: true,
    component: () => import('@/views/enforcement/sellers/Index.vue'),
    meta: {
      pageTitle: 'Sellers',
      breadcrumb: [
        {
          text: 'Enforcement',
        },
        {
          text: 'Sellers',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/sellers/:id/send',
    name: 'enforcement.sellers.send',
    component: () => import('@/views/enforcement/sellers/SendMail.vue'),
    meta: {
      pageTitle: 'Sellers/Send Email',
      breadcrumb: [
        {
          text: 'Enforcement',
        },
        {
          text: 'Sellers',
          to: { name: 'enforcement.sellers.index' },
        },
        router => ({
          text: 'Profile',
          to: {
            name: 'enforcement.seller.profile.index',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Send Email',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Enforcement',
    },
    props: true,
  },
  {
    path: '/enforcement/templates',
    name: 'enforcement.templates.index',
    component: () => import('@/views/enforcement/templates/Index.vue'),
    meta: {
      pageTitle: 'Templates',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'Templates',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/templates/create',
    name: 'enforcement.templates.create',
    component: () => import('@/views/enforcement/templates/Create.vue'),
    meta: {
      pageTitle: 'Create',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'Templates',
          to: { name: 'enforcement.templates.index' },
        },
        {
          text: 'Create',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/templates/:id/edit',
    name: 'enforcement.templates.edit',
    component: () => import('@/views/enforcement/templates/Edit.vue'),
    meta: {
      pageTitle: 'Edit',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'Templates',
          to: { name: 'enforcement.templates.index' },
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
    props: true,
  },
  {
    path: '/enforcement/logs',
    name: 'enforcement.logs.index',
    component: () => import('@/views/enforcement/logs/Index.vue'),
    props: true,
    meta: {
      pageTitle: 'Logs',
      breadcrumb: [
        {
          text: 'Enforcement',
        },
        {
          text: 'Logs',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/configuration',
    name: 'enforcement.configuration.index',
    component: () => import('@/views/enforcement/configuration/Index.vue'),
    props: true,
    meta: {
      pageTitle: 'Prioritization Configuration',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'Prioritization Configuration',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/sellers/:id/profile',
    name: 'enforcement.seller.profile.index',
    props: true,
    component: () => import('@/views/enforcement/seller-profile/Index.vue'),
    meta: {
      pageTitle: 'Seller Profile',
      breadcrumb: [
        {
          text: 'Enforcement',
        },
        {
          text: 'Sellers',
          to: { name: 'enforcement.sellers.index' },
        },
        {
          text: 'Seller Profile',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/seller/:id/contact/:contactId/edit',
    name: 'enforcement.seller.contact.edit',
    props: true,
    component: () => import('@/views/enforcement/seller-profile/components/contacts/Edit.vue'),
    meta: {
      pageTitle: 'Seller Contact Edit',
      breadcrumb: [
        {
          text: 'Enforcement',
        },
        {
          text: 'Sellers',
          to: { name: 'enforcement.sellers.index' },
        },
        router => ({
          text: 'Profile',
          to: { name: 'enforcement.seller.profile.index', params: { id: router.currentRoute.params.id } },
        }),
        {
          text: 'Seller Contact Edit',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/seller/:id/contacts/add',
    name: 'enforcement.seller.contact.multiple',
    props: true,
    component: () => import(
      '@/views/enforcement/seller-profile/components/contacts/Multiple.vue'
    ),
    meta: {
      pageTitle: 'Seller Contacts Add',
      breadcrumb: [
        {
          text: 'Enforcement',
        },
        {
          text: 'Sellers',
          to: { name: 'enforcement.sellers.index' },
        },
        router => ({
          text: 'Profile',
          to: { name: 'enforcement.seller.profile.index', params: { id: router.currentRoute.params.id } },
        }),
        {
          text: 'Seller Contacts Add',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/investigation',
    name: 'enforcement.investigation.index',
    component: () => import('@/views/enforcement/investigation/Index.vue'),
    meta: {
      pageTitle: 'Investigation',
      breadcrumb: [
        {
          text: 'Enforcement',
        },
        {
          text: 'Investigation',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/prioritization/index',
    name: 'enforcement.prioritization.index',
    component: () => import('@/views/enforcement/prioritization-table/Index.vue'),
    meta: {
      pageTitle: 'Seller Prioritization Configuration',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'Seller Prioritization Configuration',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/oauth',
    name: 'enforcement.oauth.index',
    component: () => import('@/views/enforcement/oauth2/Index.vue'),
    meta: {
      pageTitle: 'OAuth2 Configuration',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'OAuth2 Configuration',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Enforcement',
    },
  },
  {
    path: '/enforcement/oauth/:id/edit',
    name: 'enforcement.oauth.edit',
    component: () => import('@/views/enforcement/oauth2/Edit.vue'),
    meta: {
      pageTitle: 'OAuth2 Configuration/Edit',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'OAuth2 Configuration',
          to: { name: 'enforcement.oauth.index' },
        },
        {
          text: 'OAuth2 Edit',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
    props: true,
  },
  {
    path: '/enforcement/channels',
    name: 'enforcement.channels.index',
    component: () => import('@/views/enforcement/channels/Index.vue'),
    props: true,
    meta: {
      pageTitle: 'Channels',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'Enforcement',
        },
        {
          text: 'Channels',
          active: true,
        },
      ],
      action: 'update',
      resource: 'Enforcement',
    },
  },
]
