import axios from '@axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    all: [],
    uploaded: null,
  },
  getters: {
    getOne: ({ all }) => id => all.find(item => +item.id === +id),
  },
  mutations: {
    SET_UP_ITEMS(state, reports = []) {
      state.all = reports
    },
    UPDATE_ITEM({ all }, report = {}) {
      const index = all.findIndex(item => item.id === report.id)

      if (index !== -1) {
        Vue.set(all, index, report)
      }
    },
    UPDATE_UPLOADING_ITEM_STATUS({ all }, report = {}) {
      const index = all.findIndex(item => item.id === report.report_id)

      if (index !== -1) {
        Vue.set(all, index, { ...all[index], in_process: report.in_process })
      }
    },
    SET_UPLOADED_NUMBER(state, amount) {
      state.uploaded = amount
    },
  },
  actions: {
    fetch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reports/uploads', { params: queryParams })
          .then(response => {
            ctx.commit('SET_UP_ITEMS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    upload(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/reports/uploads/${id}/import`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingle(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/uploads/${id}`)
          .then(response => {
            ctx.commit('SET_UP_ITEMS', [response.data])
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    update(ctx, report = {}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/reports/uploads/${+report.id}`, report)
          .then(response => {
            ctx.commit('UPDATE_ITEM', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    clear(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/reports/uploads/${id}/refresh`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    measure(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/uploads/${id}/total`)
          .then(response => {
            ctx.commit('SET_UPLOADED_NUMBER', response.data.total)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
