import gql from 'graphql-tag'
import apollo from '@/libs/apollo'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchAll(ctx, params = {}) {
      return apollo.query({
        query: gql`
          query products($brand: Float, $limit: Int = 25, $page: Int = 1, $search: String, $available: Boolean) {
            products(page: $page, search: $search, brand: $brand, limit: $limit, available: $available) {
                data {
                  id
                  sku
                  upc
                  label
                  brand_id
                  brand {
                    brand_name
                  },
                  amazon_title,
                  key_feature,
                  key_feature_2,
                  key_feature_3,
                  key_feature_4,
                  key_feature_5,
                  description
                },
                total
                to
                from
                current
                perPage
            }
        }
        `,
        variables: params,
      })
    },
    async updateProductAttribute(ctx, data = {}) {
      return apollo.mutate({
        mutation: gql`
          mutation UpdateTaskProduct($data: UpdateTaskProduct!, $id: ID!) {
              updateTaskProduct(data: $data, id: $id) {
                id
                description
                amazon_title
                key_feature
                key_feature_2
                key_feature_3
                key_feature_4
                key_feature_5
              }
            }
          `,
        variables: {
          data: {
            description: data.description,
            amazon_title: data.amazon_title,
            key_feature: data.key_feature,
            key_feature_2: data.key_feature_2,
            key_feature_3: data.key_feature_3,
            key_feature_4: data.key_feature_4,
            key_feature_5: data.key_feature_5,
          },
          id: data.id,
        },
      })
    },
  },
}
