import gql from 'graphql-tag'

export const BRANDS_QUERY = gql`
    query brands($page: Int, $limit: Int, $isActive: Boolean, $search: String) {
        brands(page: $page, limit: $limit, isActive: $isActive, search: $search) {
            data {
                id
                brand_name
                short_name
                created_at
                is_active
                tier
                brand_groups {
                  parent_brand_name
                  short_name
                }
            }
            total
            perPage
            current
            from
            to
            previous
            next
        }
    }
`

export const BRANDS_FILTER_QUERY = gql`
    query brands($page: Int, $limit: Int, $isActive: Boolean) {
        brands(page: $page, limit: $limit, isActive: $isActive) {
            data {
                id
                brand_name
                short_name
            }
        }
    }
`

export const GET_BRAND_ACTIVE_TEMPLATES = gql`
    query brandTemplates($brand: Int!, $search: String!, $channel: BigInt!) {
        emailTemplates(search: $search, brand: $brand, isActive: true, channel: $channel) {
            data {
                id
                name
                type
            }
        }
    }
`

export const GET_BRAND_ACTIVE_SMTP = gql`
    query brandSMTP($brand: Int!) {
        brandSmtp(brand: $brand, isActive: true) {
            data {
                id
                title
                connection {
                    id
                }
            }
        }
    }
`

export const GET_BRAND_BY_ID = gql`
    query getBrandById($id: ID!) {
        getBrandById(id: $id) {
            short_name
            mpt_id
            brand_name
            is_active
            tier
            brand_groups {
                  parent_brand_name
                  short_name
                }
        }
    }
`

export const UPDATE_BRAND = gql`
    mutation updateBrand($id: ID!, $data: BrandUpdateInput!) {
        updateBrand(data: $data, id: $id) {
            created_at
        }
    }
`
export const GET_BRAND_ACTIVE_CONNECTIONS = gql`
    query getConnections($brand: Int!) {
        getConnections(brand: $brand, isActive: true) {
            id
            oauth2 {
                id
                title
            }
            smtp {
                id
                title
            }
        }
    }
`

export const GET_BRAND_CHANNELS = gql`
    query getBrandChannels($id: ID!, $limit: Int, $page: Int) {
        getBrandChannels(id: $id, limit: $limit, page: $page) {
            data {
                id
                name
                tld
                status
            }
            current
            from
            next
            perPage
            previous
            to
            total
        }
    }
`

export const GET_NOT_BRAND_CHANNELS = gql`
    query getNotBrandChannels($brandId: ID!) {
        getNotBrandChannels(brand_id: $brandId) {
            id
            name
        }
    }
`

export const ATTACH_BRAND_CHANNEL = gql`
    mutation Mutation($data: BrandChannelInput!) {
        attachBrandChannel(data: $data) {
            id
            brand_id
            channel_id
        }
    }`

export const DETACH_BRAND_CHANNEL = gql`
    mutation Mutation($data: BrandChannelInput!) {
        detachBrandChannel(data: $data)
    }`

export const GET_BRAND_ALIASES = gql`
    query($brand_id: ID!) {
        getAliasList(brand_id: $brand_id) {
            id
            brand_id
            alias
            created_at
            updated_at
        }
    }`

export const UPDATE_BRAND_ALIAS = gql`
    mutation($id: ID!, $data: AliasUpdateGqlModel!) {
        updateAlias(id: $id, data: $data) {
            id
            brand_id
            alias
        }
    }`

export const CREATE_BRAND_ALIAS = gql`
    mutation($data: AliasCreateGqlModel!) {
        createAlias(data: $data) {
            id
            brand_id
            alias
        }
    }`

export const REMOVE_BRAND_ALIAS = gql`
    mutation($id: ID!) {
        removeAlias(id: $id)
    }`
