import { areaEnum } from '@/utils/area.enum'

export default [
  {
    path: '/packaging/scan',
    name: 'packaging.scan.index',
    component: () => import('@/views/packaging/scan/Index.vue'),
    meta: {
      pageTitle: 'Product Preparation',
      breadcrumb: [
        {
          text: 'Preparation List',
          to: { name: 'packaging.list.index' },
        },
        {
          text: 'Product Preparation',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Preparation',
      area: areaEnum.PREPARATION,
    },
  },
  {
    path: '/packaging/:id/scan',
    name: 'packaging.rescan.index',
    props: true,
    component: () => import('@/views/packaging/scan/Index.vue'),
    meta: {
      pageTitle: 'Product Preparation',
      breadcrumb: [
        {
          text: 'Preparation List',
          to: { name: 'packaging.list.index' },
        },
        {
          text: 'Product Preparation',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Preparation',
      area: areaEnum.PREPARATION,
    },
  },
  {
    path: '/packaging/:id/print',
    name: 'packaging.print.index',
    props: true,
    component: () => import('@/views/packaging/print/Index.vue'),
    meta: {
      pageTitle: 'Product Preparation',
      breadcrumb: [
        {
          text: 'Preparation List',
          to: { name: 'packaging.list.index' },
        },
        {
          text: 'Product Preparation',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Preparation',
      area: areaEnum.PREPARATION,
    },
  },
  {
    path: '/packaging/list',
    name: 'packaging.list.index',
    component: () => import('@/views/packaging/list/Index.vue'),
    meta: {
      pageTitle: 'Preparation List',
      breadcrumb: [
        {
          text: 'Preparation List',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Preparation',
      area: areaEnum.PREPARATION,
    },
  },
  {
    path: '/prep/printers',
    name: 'prep.printers',
    component: () => import('@/views/packaging/printers/Index.vue'),
    meta: {
      pageTitle: 'Printers List',
      breadcrumb: [
        {
          text: 'Preparation',
        },
        {
          text: 'Printers List',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Printer',
      area: areaEnum.PREPARATION,
    },
  },
]
