import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_CHANNELS = gql`
    query channels($page: Int, $limit: Int, $search: String, $isActive: Boolean) {
        getChannels(page: $page, limit: $limit, search: $search, isActive: $isActive) {
            from
            total
            to
            current
            perPage
            next
            previous
            data {
                id
                name
                platform
                locale
                status
                created_at
                country{
                    id
                    name
                }
            }
        }
    }
`

export const CHANNELS_FILTER_QUERY = gql`
    query channels($page: Int, $limit: Int, $search: String, $isActive: Boolean) {
        getChannels(page: $page, limit: $limit, search: $search, isActive: $isActive) {
            data {
                id
                name
                country_id
                platform
            }
        }
    }
`

export const UPDATE_CHANNEL = gql`
    mutation updateChannel($id: ID!, $data: UpdateChannelInput!) {
        updateChannel(id: $id, data: $data) {
            id
        }
    }
`
