import gql from 'graphql-tag'
import apollo from '@/libs/apollo'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchCopyWriters() {
      return apollo.query({
        query: gql`
          query copyWriters {
            copyWriters {
              id
              name
              email
              role
            }
          }
        `,
      })
    },
  },
}
