var render, staticRenderFns
import script from "./FeatherIcon.vue?vue&type=script&lang=js"
export * from "./FeatherIcon.vue?vue&type=script&lang=js"
import style0 from "./FeatherIcon.vue?vue&type=style&index=0&id=b3216f64&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.33_css-loader@6.11.0_webpack@5.82.1__ejs@3.1.10_hand_wrd73fds6m2flssqx6via5u5fq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports