<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import { isUserLoggedIn } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import { mapActions } from 'vuex'
import gql from 'graphql-tag'
import {
  GenericToast,
} from '@/libs/toasts'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutAmazon = () => import('@/layouts/amazon-preview/LayoutAmazon.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutAmazon,

  },
  setup() {
    const {
      skin,
      skinClasses,
    } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      if (this.$route.meta.layout === 'amazon') return 'layout-amazon'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement)
        .value
        .trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
        .value
        .slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  created() {
    this.boot()
  },
  methods: {
    ...mapActions({
      getMe: 'auth/getMe',
      getBrands: 'brands/fetch',
      getCountries: 'countries/fetch',
      getChannels: 'channels/fetch',
    }),
    boot() {
      const isLoggedIn = isUserLoggedIn()

      if (isLoggedIn) {
        Promise.all([
          this.getMe(),
          this.getBrands(),
          this.getCountries(),
          this.getChannels(),
        ])
          .then(responses => {
            const { ability } = responses[0]?.data ?? {}

            if (ability) {
              localStorage.setItem(useJwt.jwtConfig.storageUserKey, JSON.stringify(responses[0]?.data))
              this.$ability.update(ability)
            }
            this.subscribe()
          })
      }
    },
    subscribe() {
      this.$apollo.addSmartSubscription('printFinished', {
        query: gql`subscription printFinished($userId: ID!) {
          printFinished(userId: $userId) {
            title
            message
            hasError
          }
        }`,
        variables() {
          return {
            userId: this.me.id,
          }
        },
        result({ data }) {
          if (data.printFinished) {
            this.$toast(GenericToast(data.printFinished.message, {
              title: data.printFinished.title,
              icon: data.printFinished.hasError ? 'AlertCircleIcon' : 'CheckCircleIcon',
              variant: data.printFinished.hasError ? 'danger' : 'success',
            }))
          }
        },
      })
    },
  },
}
</script>
