import buildURL from 'axios/lib/helpers/buildURL'
import { $themeColors } from '@themeConfig'

export default function getAvatarLink(name = 'Unknown') {
  return buildURL('https://eu.ui-avatars.com/api/', {
    name,
    color: $themeColors.primary,
    background: '#efece8',
  })
}
