import axios from '@axios'

export default {
  namespaced: true,
  state: {
    fields: [],
  },
  getters: {
    visibleFields(state) {
      return state.fields.filter(field => !field.hide)
    },
  },
  mutations: {
    SET_FIELDS(state, fields) {
      state.fields = fields
    },
  },
  actions: {
    fetch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reports/business', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    download(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reports/business', {
            headers: {
              Accept: 'text/csv',
            },
            responseType: 'blob',
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
