import axios from '@/libs/axios'
import getAvatarLink from '@/libs/avatar'

export default {
  namespaced: true,
  state: {
    user: {},
  },
  getters: {
    avatarLink({ user }) {
      if (user.name) {
        return getAvatarLink(user.name)
      }
      return undefined
    },
    role({ user }) {
      return user?.role
    },
    getUser({ user }) {
      return user
    },
  },
  mutations: {
    SET_UP_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    async getMe({ commit }) {
      const response = await axios.get('/me')
      commit('SET_UP_USER', response.data)
      return response
    },
    changePassword(_, payload = {}) {
      return axios.post('/change-password', payload)
    },
    async update({ commit }, payload = {}) {
      const response = await axios.put('/me', payload)
      commit('SET_UP_USER', response.data)
      return response
    },
  },
}
