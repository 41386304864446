import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
    query users {
        users {
            id
            name
            role
        }
    }
`

export const GET_USER_ARCHIVED = gql`
    query getUserArchived($email: String!) {
        getUserArchived(email: $email) {
            id
            name
            deleted_at
        }
    }
`

export const RECOVER_USER = gql`
    mutation recoverUser($id: ID!) {
    recoverUser(id: $id)
    }
`

export const GET_USER_NOTIFICATIONS_LIST = gql`
    query getUserNotificationsList($user_id: ID!) {
        getUserNotificationsList(user_id: $user_id) {
            id
            type
            name
            notificationSetting{
                id
                type_id
                notifiable_id
                notifiable_type
                is_active
                period
                start_date
                time
                timezone
            }
        }
    }
`

export const TOGGLE_NOTIFICATION_SETTING = gql`
    mutation toggleNotificationSetting($type_id: ID!, $is_active: Boolean!, $user_id: ID!) {
        toggleNotificationSetting(type_id: $type_id, is_active: $is_active, user_id: $user_id){
            id
        }
    }
`

export const UPDATE_NOTIFICATION_SETTING = gql`
    mutation updateNotificationSetting($type_id: ID!, $data: SettingInput!, $user_id: ID!) {
        updateNotificationSetting(type_id: $type_id, data: $data, user_id: $user_id){
            id
            type_id
            is_active
            time
            id
            notifiable_type
            period
            start_date
            timezone
        }
    }
`
