import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const COUNTRIES_FILTER_QUERY = gql`
    query countries($page: Int, $limit: Int, $isActive: Boolean) {
        getCountries(page: $page, limit: $limit, isActive: $isActive) {
            data {
                id
                name
                locale
            }
        }
    }
`
