import axios from '@axios'

export default {
  namespaced: true,
  state: {
    fields: [],
  },
  getters: {
    visibleFields(state) {
      return state.fields.filter(field => !field.hide).concat([
        {
          field: 'actions',
          cellClass: 'custom-ag-table-action-cell',
          hide: false,
        },
      ])
    },
  },
  mutations: {
    SET_FIELDS(state, fields) {
      state.fields = fields
    },
  },
  actions: {
    fetch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reports/snapshot/inventory', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    download(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/reports/snapshot/inventory', {
            headers: {
              Accept: 'text/csv',
            },
            responseType: 'blob',
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createComment(ctx, { entity_id, payload = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/comments', {
            ...payload,
            entity_id,
            entity_type: 'inventory_snapshot_report',
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
