import store from '@/store'

export default [
  {
    path: '/users',
    name: 'users.list',
    component: () => import('@/views/users/list/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Users',
          active: false,
        },
      ],
      action: 'read',
      resource: 'Users',
    },
  },
  {
    path: '/users/:id',
    name: 'users.view',
    component: () => import('@/views/users/view/UsersView.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Users',
          to: { name: 'users.list' },
        },
        () => ({
          text: store?.state?.users?.selected?.name ?? '...',
          to: { name: 'users.view', params: { id: store?.state?.users?.selected?.id } },
        }),
        {
          text: 'View',
          active: true,
        },
      ],
      action: 'read',
      resource: 'User',
    },
    props: true,
  },
  {
    path: '/users/:id/edit',
    name: 'users.edit',
    component: () => import('@/views/users/edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Edit User',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Users',
          to: { name: 'users.list' },
        },
        () => ({
          text: store?.state?.users?.selected?.name ?? '...',
          to: { name: 'users.view', params: { id: store?.state?.users?.selected?.id } },
        }),
        {
          text: 'Edit',
          active: true,
        },
      ],
      action: 'read',
      resource: 'User',
    },
    props: true,
  },
]
