export default [
  {
    path: '/inventory/locations',
    name: 'inventory.index',
    component: () => import('@/views/inventory-location/Index.vue'),
    meta: {
      pageTitle: 'Inventory List',
      breadcrumb: [
        {
          text: 'Inventory List',
          active: false,
        },
      ],
      action: 'read',
      resource: 'CycleCount',
    },
  },
  {
    path: '/inventory/scan',
    name: 'inventory.scan',
    component: () => import('@/views/inventory-location/Scan.vue'),
    meta: {
      pageTitle: 'Inventory Count Cycle',
      breadcrumb: [
        {
          text: 'Inventory List',
          to: { name: 'inventory.index' },
        },
        {
          text: 'Inventory Count Cycle',
          active: true,
        },
      ],
      action: 'read',
      resource: 'CycleCount',
    },
  },
]
