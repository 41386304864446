import apollo from '@/libs/apollo'
import { COUNTRIES_FILTER_QUERY } from '@/graphql/enforcement/countries'

export default {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    getCountries({ all }) {
      return all
    },
  },
  mutations: {
    SET_COUNTRIES(state, countries = []) {
      state.all = countries
    },
  },
  actions: {
    fetch({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        apollo.query({
          query: COUNTRIES_FILTER_QUERY,
          variables: {
            ...params,
            limit: 100,
            isActive: true,
          },
        })
          .then(response => {
            commit('SET_COUNTRIES', response.data.getCountries.data.map(country => ({
              ...country,
              id: +country.id,
              label: country.name,
            })))
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
