export default [
  {
    path: '/outbound/shipment/list',
    name: 'outbound.shipment.list.index',
    component: () => import('@/views/outbound/shipment/list/Index.vue'),
    meta: {
      pageTitle: 'Outbound Shipment List',
      breadcrumb: [
        {
          text: 'Outbound Shipment List',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Outbound',
    },
  },
  {
    path: '/outbound/shipment/create',
    name: 'outbound.shipment.create.index',
    component: () => import('@/views/outbound/shipment/create/Index.vue'),
    meta: {
      pageTitle: 'Outbound Shipment Create',
      breadcrumb: [
        {
          text: 'Outbound Shipment List',
          to: { name: 'outbound.shipment.list.index' },
        },
        {
          text: 'Outbound Shipment Create',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Outbound',
    },
  },
  {
    path: '/outbound/shipment/:id/details/',
    name: 'outbound.shipment.details.index',
    props: true,
    component: () => import('@/views/outbound/shipment/details/Index.vue'),
    meta: {
      pageTitle: 'Outbound Shipment Details',
      breadcrumb: [
        {
          text: 'Outbound Shipment List',
          to: { name: 'outbound.shipment.list.index' },
        },
        {
          text: 'Outbound Shipment Details',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Outbound',
    },
  },
  {
    path: '/outbound/shipment/:id/scan',
    name: 'outbound.shipment.scan.index',
    props: true,
    component: () => import('@/views/outbound/shipment/scan/Index.vue'),
    meta: {
      pageTitle: 'Outbound Shipment Scan',
      breadcrumb: [
        {
          text: 'Outbound Shipment List',
          to: { name: 'outbound.shipment.list.index' },
        },
        router => ({
          text: 'Outbound Shipment Details',
          to: {
            name: 'outbound.shipment.details.index',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Outbound Shipment Scan',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Outbound',
    },
  },
  {
    path: '/outbound/shipment/:id/summary',
    name: 'outbound.shipment.summary.index',
    props: true,
    component: () => import('@/views/outbound/shipment/summary/Index.vue'),
    meta: {
      pageTitle: 'Outbound Shipment Summary',
      breadcrumb: [
        {
          text: 'Outbound Shipment List',
          to: { name: 'outbound.shipment.list.index' },
        },
        router => ({
          text: 'Outbound Shipment Details',
          to: {
            name: 'outbound.shipment.details.index',
            params: { id: router.currentRoute.params.id },
          },
        }),
        router => ({
          text: 'Outbound Shipment Scan',
          to: {
            name: 'outbound.shipment.scan.index',
            params: { id: router.currentRoute.params.id },
          },
        }),
        {
          text: 'Summary',
          active: true,
        },
      ],
      action: 'create',
      resource: 'Outbound',
    },
  },
  {
    path: '/outbound/case-erase/list',
    name: 'outbound.case-erase.list.index',
    component: () => import('@/views/outbound/case-erase/list/Index.vue'),
    meta: {
      pageTitle: 'Case Erase List',
      breadcrumb: [
        {
          text: 'Case Erase List',
          active: true,
        },
      ],
      action: 'read',
      resource: 'CaseErase',
    },
  },
  {
    path: '/outbound/case-erase/scan',
    name: 'outbound.case-erase.scan.index',
    component: () => import('@/views/outbound/case-erase/scan/Index.vue'),
    meta: {
      pageTitle: 'Case Erase Scan',
      breadcrumb: [
        {
          text: 'Case Erase List',
          to: { name: 'outbound.case-erase.list.index' },
        },
        {
          text: 'Case Erase Scan',
          active: true,
        },
      ],
      action: 'create',
      resource: 'CaseErase',
    },
  },
]
