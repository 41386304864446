import apollo from '@/libs/apollo'
import { GET_USERS } from '@/graphql/users'

export default {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    getUsers({ all }) {
      return all
    },
  },
  mutations: {
    SET_USERS(state, users = []) {
      state.all = users
    },
  },
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, reject) => {
        apollo.query({
          query: GET_USERS,
        })
          .then(response => {
            commit('SET_USERS', response.data.users.map(user => ({
              ...user,
              label: user.name,
            })))
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
