export default [
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Index.vue'),
    meta: {
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Products',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Products',
    },
  },
  {
    path: '/products/transparency-codes',
    name: 'products.transparency-codes',
    component: () => import('@/views/products/codes/Index.vue'),
    meta: {
      pageTitle: 'Transparency Codes',
      breadcrumb: [
        {
          text: 'Transparency Codes',
        },
      ],
      action: 'read',
      resource: 'ProductCodes',
    },
  },
  {
    path: '/products/fbm-products',
    name: 'products.fbm-products',
    component: () => import('@/views/products/fbm-products/Index.vue'),
    meta: {
      pageTitle: 'FBM Products',
      breadcrumb: [
        {
          text: 'FBM Products',
        },
      ],
      action: 'read',
      resource: 'Products',
    },
  },
  {
    path: '/settings/products',
    name: 'product.settings',
    component: () => import('@/views/products/product-settings/Index.vue'),
    meta: {
      pageTitle: 'Products',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Products',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Products',
    },
  },
]
