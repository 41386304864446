import { getHomeRouteForLoggedInUser, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

export default function (router) {
  router.beforeEach((to, from, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!canNavigate(to)) {
      if (!isLoggedIn) return next({ name: 'login' })

      return next({ name: 'not-authorized' })
    }

    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      next(getHomeRouteForLoggedInUser())
    }

    return next()
  })
}
