import apollo from '@/libs/apollo'
import { CHANNELS_FILTER_QUERY } from '@/graphql/enforcement/channels'

export default {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    getChannels({ all }) {
      return all
    },
    getUsChannelOrFirst({ all }) {
      return all.find(channel => channel.id === 1) || all[0]
    },
    getFilteredChannels: state => (ids = [], platforms = []) => state.all.filter(channel => {
      const isPlatform = platforms.length > 0 ? platforms.includes(channel.platform) : false
      const isIds = ids.length > 0 ? ids.includes(channel.id) : false
      return !isPlatform && !isIds
    }),
  },
  mutations: {
    SET_CHANNELS(state, channels = []) {
      state.all = channels
    },
  },
  actions: {
    fetch({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        apollo.query({
          query: CHANNELS_FILTER_QUERY,
          variables: {
            ...params,
            limit: 100,
            isActive: true,
          },
        })
          .then(response => {
            commit('SET_CHANNELS', response.data.getChannels.data.map(channel => ({
              ...channel,
              id: +channel.id,
              label: channel.name,
            })))
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
