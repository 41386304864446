import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    update(ctx, { id, payload = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/comments/${id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    remove(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/comments/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
