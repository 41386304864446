import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      me: state => state.auth.user,
    }),
    ...mapGetters({
      myRole: 'auth/role',
      myAvatar: 'auth/avatarLink',
    }),
  },
}
