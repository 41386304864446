export default [
  {
    path: '/settings/inventory-days-configuration',
    name: 'inventory-days-configuration',
    component: () => import('@/views/settings/InventoryDays.vue'),
    meta: {
      pageTitle: 'Inventory Days Configuration',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Inventory Days Configuration',
          active: true,
        },
      ],
      action: 'read',
      resource: 'InventoryDaysConfiguration',
    },
  },
  {
    path: '/labels',
    name: 'labels.index',
    component: () => import('@/views/settings/labels/labelsList.vue'),
    meta: {
      pageTitle: 'Labels List',
      breadcrumb: [
        {
          text: 'Settings',
        },
        {
          text: 'General',
        },
        {
          text: 'Labels List',
          active: true,
        },
      ],
      action: 'read',
      resource: 'Printer',
    },
  },
]
