import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    unusedUserPrinters: [],
    printers: [],
    isSidebarActive: false,
    selectedLabel: null,
    isLoading: false,
  },
  getters: {},
  mutations: {
    toggleQuickPrinterSwitcher(state, label) {
      state.isSidebarActive = !state.isSidebarActive
      state.selectedLabel = label
    },
    closeQuickPrinterSwitcher(state) {
      state.isSidebarActive = false
      state.selectedLabel = null
    },
    setPrinters(state, printers) {
      state.printers = printers
    },
    setUnused(state, printers) {
      state.unusedUserPrinters = printers
    },
    setLoading(state, value) {
      state.isLoading = value
    },
  },
  actions: {
    async fetchSingle(ctx, id) {
      return axios.get(`/printers/${id}`)
    },
    async update(ctx, printer = {}) {
      return axios.put(`/printers/${printer.id}`, printer)
    },
    async remove(ctx, id) {
      return axios.delete(`/printers/${id}`)
    },
    async attach(ctx, { printerId, userId, labelId }) {
      return axios.put(`/users/${userId}/printers`, {
        printerId,
        labelId,
      })
    },
    async getUserPrinters(ctx, userId) {
      return axios.get(`/users/${userId}/printers`)
        .then(response => {
          ctx.commit('setPrinters', response.data)
          return response
        })
    },
    async detach(ctx, { printerId, userId }) {
      return axios.delete(`/users/${userId}/printers/${printerId}`)
    },
    async getUnusedPrinters(ctx, user) {
      return axios.get(`/users/${user}/unused-printers`)
        .then(response => {
          ctx.commit('setUnused', response.data)
          return response
        })
    },
    async listDeliveryPrinters(ctx, params = {}) {
      return axios.get('/delivery/printers', {
        params,
      })
    },
    async createDeliveryPrinter(ctx, printer = {}) {
      return axios.post('/delivery/printers', printer)
    },
    async makeDeliveryDefault(ctx, id) {
      return axios.patch(`/delivery/printers/${id}/default`)
    },
    async listPrepPrinters(ctx, params = {}) {
      return axios.get('/prep/printers', {
        params,
      })
    },
    async createPrepPrinter(ctx, printer = {}) {
      return axios.post('/prep/printers', printer)
    },
    async makePrepDefault(ctx, id) {
      return axios.patch(`/prep/printers/${id}/default`)
    },
    async retrieveMyDeliveryPrinters({ commit }) {
      return axios.get('/delivery/printers/actual')
        .then(response => {
          commit('setPrinters', response.data)
          return response
        })
    },
    async retrieveMyPrepPrinters({ commit }) {
      return axios.get('/prep/printers/actual')
        .then(response => {
          commit('setPrinters', response.data)
          return response
        })
    },
    async fetchLabels(ctx, params) {
      return axios.get('/labels', {
        params,
      })
    },
    async updateLabel(ctx, { id, title }) {
      return axios.patch(`/labels/${id}`, {
        title,
      })
    },
  },
}
