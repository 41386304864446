export default [
  {
    path: '/netsuite-receipts',
    name: 'netsuite-receipts.index',
    component: () => import('@/views/netsuite-receipts/Index.vue'),
    meta: {
      pageTitle: 'Netsuite Receipts Bulk Upload',
      breadcrumb: [
        {
          text: 'Netsuite Receipts Bulk Upload',
          active: false,
        },
      ],
      action: 'create',
      resource: 'NetsuiteReceipts',
    },
  },
]
