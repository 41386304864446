import apollo from '@/libs/apollo'
import { BRANDS_FILTER_QUERY } from '@/graphql/brand'

export default {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    getBrands({ all }) {
      return all
    },
  },
  mutations: {
    SET_BRANDS(state, brands = []) {
      state.all = brands
    },
  },
  actions: {
    fetch({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        apollo.query({
          query: BRANDS_FILTER_QUERY,
          variables: {
            ...params,
            limit: 100,
            isActive: true,
          },
        })
          .then(response => {
            commit('SET_BRANDS', response.data.brands.data.map(brand => ({
              ...brand,
              label: brand.brand_name,
            })))
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
